<style scoped>
  /* .create-nav{
    background: rgba(255,255,255,0)!important;
  } */
  .create-book {
    position: relative;
  }

  .book-list {
    padding: 0 0 90px 15px;
  }

  .book-box {
    padding: 20px 15px 20px 0;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    font-size: 0.875rem;
    color: #666;
  }

  .book-box .cover {
    float: left;
    width: 100px;
    height: 145px;
    border-radius: 5px;
  }

  .book-box .info {
    margin-left: 110px;
    font-size: 0.8rem;
  }

  .book-box .info .bookname {
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8px;
    height: 22px;
    line-height: 22px;
    color: #323232;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 60px;
    position: relative;
  }

  .book-box .info .bookname button {
    height: 22px;
    line-height: 20px;
    font-size: 0.7rem;
    color: #fff;
    background: #547CFF;
    padding: 0 10px;
    border-radius: 11px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .book-box .info .p-brief {
    font-size: 0.7rem;
    line-height: 18px;
    height: 54px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
  }

  .book-box .info .time span {
    font-size: 0.7rem;
    margin-right: 10px;
  }

  .book-box .info .time span:last-of-type {
    margin-right: 0;
  }

  .book-box .book-btn {
    margin-top: 8px;
  }

  .book-box .book-btn button {
    cursor: pointer;
    font-size: 0.75rem;
    color: #444444;
    height: 28px;
    line-height: 26px;
    width: 72px;
    text-align: center;
    border: 1px solid #b5b5b5;
    border-radius: 3px;
    padding: 0;
    margin-right: 8px;
    background: transparent;
  }

  .book-box .book-btn button:last-of-type {
    margin-right: 0;
  }

  .book-box .info .bookname button:disabled,
  .book-box .book-btn button:disabled {
    opacity: 0.7;
  }

  .book-box .info .bookname button:disabled {
    background: #9fa5f1;
    opacity: 1;
    color: rgba(255, 255, 255, 0.7);
  }

  .btn-bottom-box button:disabled {
    background: #9fa5f1;
    opacity: 1;
    color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 3px 6px rgba(84, 124, 255, 0.29);
  }

  .create-btn {
    padding: 10px 20px 30px;
    position: fixed;
    bottom: 0;
    /*background: #fff;*/
  }

  .create-btn button img {
    vertical-align: middle;
    height: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }

  .booknull {
    position: fixed;
    top: 51px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    /*主轴居中对齐*/
    justify-content: center;
    /*侧轴居中对齐*/
    align-items: center;
  }

  .booknullimg {
    text-align: center;
    position: relative;
    top: -51px;
  }

  .booknullimg img {
    width: 90px;
  }

  .booknullimg p {
    display: block;
    color: #bfc2e7;
  }

  .booknull .create-btn {
    position: fixed;
    bottom: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  @media only screen and (max-width:370px) {
    .book-box .cover {
      float: left;
      width: 90px;
      height: 135px;
      border-radius: 5px;
    }

    .book-box .info {
      margin-left: 100px;
    }

    .book-box .info .bookname {
      font-size: 0.95rem;
      font-weight: 600;
      margin-bottom: 5px;
      height: 20px;
      line-height: 20px;
    }

    .book-box .info .bookname button {
      height: 20px;
    }

    .book-box .info .p-brief {
      margin-bottom: 5px;
    }

    .book-box .book-btn button {
      width: 68px;
      margin-right: 8px;
    }

    .book-box {
      padding: 20px 10px 20px 0;
    }
  }

  @media only screen and (max-width:345px) {
    .book-box .book-btn button {
      width: 62px;
      margin-right: 5px;
    }
  }

  @media only screen and (max-width:320px) {
    .book-box .book-btn button {
      width: 60px;
      margin-right: 5px;
    }
  }

  .isbtn0 {
    margin-top: 8px;
  }

  .isbtn0_btn {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    background: #547CFF;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    color: #FFFFFF;
    height: 29px;
  }

  .guide {
    /* background:red; */
    position: absolute;
    left: 50%;
    top: 79%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }

  .guide>img {
    width: auto;
    height: 154px;
  }

  .guide3 {
    top: 86%;
    left: 50%;
  }

  .guide3>img {
    height: 114px;
  }

  .menubox {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }

  .isbtn0_btn1 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    background: #547CFF;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    color: #FFFFFF;
    height: 29px;
    position: relative;
    z-index: 3;
    box-shadow: 0px 0px 0px 7px #fff;
    border: 0px;
  }

  .release_dialong {
    background-color: #fff;
    width: 300px;
    /* height: 154.5px; */
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .release_dialong_img {
    height: 31px;
  }

  .release_dialong_img>img {
    width: 60px;
    height: 66px;
    position: absolute;
    top: -35px;
    left: 50%;
    margin-left: -30px;
  }

  .release_dialong_title {
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    color: #202020;
    text-align: center;
    margin-top: 3px;
  }

  .release_dialong_cont {
    padding: 0 32px 0 30px;
    margin-top: 8px;
    font-size: 13px;
    font-weight: bold;
    color: #202020;
  }

  .choose {
    border-top: #E7E9EE 1px solid;
    float: left;
    width: 49.838541%;
    text-align: center;
    padding: 12px 0 0 0;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 400;
    color: #547CFF;
  }

  .choose:nth-of-type(1) {
    border-right: #E7E9EE 1px solid;
  }

  .dialog-bg {
    z-index: 1001;
  }

  .release_dialong_choose {
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    bottom: -5px;
  }
  .pagebgimg{
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100%;height: 100%;
  }
  .pagebgimg>img{
    width: 100%;height: 100%;
  }
</style>
<template>
  <div class="create-book">
    <div class="book-list-box" v-show="bookList.length <= 0">
      <div class="book-list">
        <div class="book-box">
          <img class="cover" :src="ugcBookDefault.coverName">
          <div class="info">
            <p class="bookname">
              {{ugcBookDefault.ugcBookName}}
            </p>
            <p class="p-brief">
              {{ugcBookDefault.intro}}
            </p>
            <p class="time"><span>字数：97</span><span>创建时间：{{new_date}}</span></p>
            <div class="isbtn0">
              <button @click="isbtn0_clic" :class="[isbtn0_btn1 ? 'isbtn0_btn1':'isbtn0_btn']">点击制作</button>
            </div>
          </div>
        </div>
      </div>
      <div v-show="ipshow == 0">
        <!-- ip蒙层 -->
        <div v-show="guidebox == 'block'" @click="clicmenubox" class="menubox"></div>
        <!-- ip形象 -->
        <div class="guide" @click="clicmenubox" v-show="guide == 1">
          <img src="../../assets/image/guide1.png" alt="">
        </div>
        <div class="guide" @click="clicmenubox" v-show="guide == 2">
          <img src="../../assets/image/guide2.png" alt="">
        </div>
        <div class="guide guide3" @click="clicmenubox" v-show="guide == 3">
          <img src="../../assets/image/guide3.png" alt="">
        </div>
      </div>

    </div>
    <div class="book-list-box" v-show="bookList.length > 0">
      <div class="book-list">
        <div class="book-box" v-for="(item, index) in bookList" :key="index" :data-id="item.id">
          <img class="cover" :src="item.coverName" alt="item.bookName">
          <div class="info">
            <p class="bookname">
              {{item.ugcBookName}}
              <button v-if="item.isVerify===0">未发布</button>
              <button v-if="item.isVerify===1 || item.isVerify===3">预发布</button>
              <button v-if="item.isVerify===2">已发布</button>
            </p>
            <p class="p-brief">{{item.intro}}</p>
            <p class="time"><span>字数：{{item.sumWords}}</span><span>创建时间：{{item.addTime}}</span></p>
            <div class="book-btn">
              <button v-if="item.isVerify===0 && item.verifyNum === 0 && item.isAuthor" @click="goPublishOrApply(index, item.id, item.isVerify, item.rackStatus, '确定要去发布？',item.latestChapterId)">去发布</button>
              <button v-if="(item.isVerify===1 && item.verifyNum === 1) || (!item.isAuthor && item.isVerify===0 && item.verifyNum === 0)" disabled>去发布</button>
              <button v-if="(item.isVerify===2 || (item.isVerify===0 && item.verifyNum >  0)) && item.isAuthor" @click="goPublishOrApply(index, item.id, item.isVerify, item.rackStatus, '确定要继续发布？',item.latestChapterId)">继续发布</button>
              <button v-if="(item.isVerify===3 || (item.isVerify===1 && item.verifyNum >  1)) || ((item.isVerify===2 || (item.isVerify===0 && item.verifyNum >  0)) && !item.isAuthor)" disabled>继续发布</button>
              <!-- <button v-if="item.isVerify===2" @click="goPublishOrApply(index, item.id, item.isVerify, item.rackStatus, '确定要申请下架？')">申请下架</button> -->
              <!-- <button v-if="item.isVerify===3" @click="goPublishOrApply(index, item.id, item.isVerify, item.rackStatus, '确定要去发布？',item.latestChapterId)">继续发布</button> -->
              <button @click="goChapterManage(item.id)">作品管理</button>
              <button v-if="item.isVerify===0 || item.isVerify===2 || item.isVerify===3" @click="goCreate(item.id, item.myLatestChapterId)">继续创作</button>
              <button v-if="item.isVerify===1" @click="goCreate(item.id, null)">继续创作</button>
            </div>
          </div>
        </div>
      </div>
      <base-dialog v-show="dialogShow" :dialogContent="dialogContent" @dialogCancel="dialogCancelFn"
        @dialogSure="dialogSureFn()"></base-dialog>
    </div>
    <div class="btn-bottom-box fix-bottom-btn create-btn">
      <button class="btn-bottom" @click="goCreateBook"><img src="../../assets/image/icon_create.png">创建作品</button>
    </div>
    <!-- 审核通知 -->
    <div v-show="isrelease_dialong">
      <div class="menubox">
        <div class="release_dialong">
          <div class="release_dialong_img">
            <img src="../../assets/image/releaseimage.png" alt="">
          </div>
          <div class="release_dialong_title">
            审核通知
          </div>
          <div class="release_dialong_cont">
            {{release_dialong_cont_text}}
          </div>
          <div class="release_dialong_choose">
            <div class="choose" @click="iknow">
              我知道了
            </div>
            <div class="choose" @click="Work_management">
              作品管理
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagebgimg">
      <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import baseDialog from '../../components/dialog/base-dialog.vue'
export default {
  components: {
    baseDialog
  },
  data () {
    return {
      // 当天时间
      new_date: null,
      handleIndex: '',
      ugcBookId: '',
      isVerify: '', // 审核状态
      rackStatus: '', // 上下架状态
      dialogShow: false,
      dialogContent: '确定要申请下架？',
      bookList: [],
      // 新用户引导ip
      guide: null,
      // 新用户引导ip蒙层
      guidebox: '',
      // 按钮类名切换
      isbtn0_btn1: false,
      isrelease_dialong: false,
      ugcBookDefault: {
        chapterTitle: null,
        coverId: null,
        intro: null,
        showType: null,
        ugcBookName: null,
        coverName: ''
      },
      ipshow: true,
      release_dialong_cont_text: null,
      ugcbookid: '',
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png')
      // backpicPath: ''
    }
  },
  computed: {},
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    // 获取当天的日期
    this.addDate()
    // 获取数据列表 判断  是否加载模板
    this.getpagelistdefult()
  },
  mounted () {
    localStorage.setItem('coverId', '')
  },
  methods: {
    // 点击蒙层切换ip形象
    clicmenubox () {
      if (this.guide === 1) {
        this.guide = 2
      } else if (this.guide === 2) {
        this.guide = 3
        this.isbtn0_btn1 = true
      } else if (this.guide === 3) {
        this.guide = null
        this.guidebox = null
        this.isbtn0_btn1 = false
        this.ipshow = 1
      }
    },
    // 去发布机审
    iknow () {
      this.isrelease_dialong = false
    },
    Work_management () {
      this.$router.push({
        path: '/chapterManage',
        query: {
          bookId: this.ugcbookid
        }
      })
    },
    // ip引导
    switch1 () {
      this.ipshow = 0
      this.guide = 1
      this.guidebox = 'block'
    },
    switch2 () {
      this.guide = 2
    },
    switch3 () {
      this.guide = 3
      this.isbtn0_btn1 = true
    },
    switch4 () {
      this.ipshow = 1
      this.guide = null
      this.guidebox = null
      this.isbtn0_btn1 = false
    },
    // 点击制作
    isbtn0_clic () {
      this.$router.push({
        name: 'bookEdit',
        query: {
          making: 1,
          // coverId: this.ugcBookDefault.coverId,
          ugcBookDefault: this.ugcBookDefault
          // coverName: this.ugcBookDefault.coverName

        }
      })
    },
    // 去发布or下架弹框展示
    goPublishOrApply (index, id, isVerify, rackStatus, text, chapterId) {
      this.axios({
        method: 'get',
        url: '/v1/look/examine',
        params: {
          ugcBookId: id,
          chapterId: chapterId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          // if (res.data.content.sum < 60) { // 几个章节不满60分
          //   this.isrelease_dialong = true
          //   this.ugcbookid = id
          //   this.release_dialong_cont_text = '您的作品《' + res.data.content.ugcBookName + '》因：[章节评分不满60分未能通过审核]'
          // } else if (res.data.content.chapterNum < 10) {
          //   this.isrelease_dialong = true
          //   this.ugcbookid = id
          //   this.release_dialong_cont_text = '您的作品《' + res.data.content.ugcBookName + '》因：[可阅读章节小于10章未能通过审核]]'
          // } else {
          // 调取是否有新的章节需要被发布
          this.handleIndex = index
          this.ugcBookId = id
          this.isVerify = isVerify
          this.rackStatus = rackStatus
          this.dialogShow = true
          this.dialogContent = text
          // }
        }
      })
    },
    // 作品管理
    goChapterManage (id) {
      this.$router.push({
        path: '/chapterManage',
        query: {
          bookId: id
        }
      })
    },
    // 去创建
    goCreateBook () {
      this.$store.state.bookInfo = {
        cover: {
          coverId: '',
          coverImg: ''
        },
        bookName: '',
        bookIntro: '',
        startChapterName: ''
      }
      this.$router.push({
        path: '/bookEdit'
      })
    },
    // 弹框取消
    dialogCancelFn () {
      this.dialogShow = false
    },
    // 弹框确定按钮
    dialogSureFn () {
      var that = this
      if (this.dialogContent === '确定要去发布？') {
        that.axios({
          method: 'get',
          url: '/v1/applyForRelease',
          params: {
            ugcBookId: that.ugcBookId,
            isVerify: that.isVerify,
            rackStatus: that.rackStatus
          }
        }).then((res) => {
          if (res.data.head.flag === 0) {
            this.bookList[this.handleIndex].isVerify = 3
            this.getpagelistdefult()
          } else {
            this.$toast(res.data.head.desc)
          }
        })
      } else if (this.dialogContent === '确定要继续发布？') {
        that.axios({
          method: 'get',
          url: '/v1/applyForRelease',
          params: {
            ugcBookId: that.ugcBookId,
            // isVerify: that.isVerify,
            isVerify: 0,
            rackStatus: that.rackStatus
          }
        }).then((res) => {
          // console.log(res)
          if (res.data.head.flag === 0) {
            this.bookList[this.handleIndex].isVerify = 3
            this.getpagelistdefult()
          } else {
            this.$toast(res.data.head.desc)
          }
        })
      } else if (this.dialogContent === '确定要申请下架？') {
        that.axios({
          method: 'get',
          url: '/v1/applyForRelease',
          params: {
            ugcBookId: that.ugcBookId,
            isVerify: that.isVerify,
            rackStatus: that.rackStatus
          }
        }).then((res) => {
          if (res.data.head.flag === 0) {
            this.bookList[this.handleIndex].isVerify = 3
          } else {
            this.$toast(res.data.head.desc)
          }
        })
      }
      that.dialogShow = false
    },
    // 继续创作
    goCreate (bookId, chapterId) {
      localStorage.setItem('sltCptBgActive', '')
      localStorage.setItem('sltCptBgmActive', '')
      this.$store.state.sltCptBgActive = {
        firstTypeIndex: '',
        firstTypeId: '',
        subTypeIndex: '',
        subTypeId: '',
        listActive: ''
      }
      this.$store.state.sltCptBgmActive = {
        firstTypeIndex: '',
        firstTypeId: '',
        subTypeIndex: '',
        subTypeId: '',
        listActive: ''
      }
      this.$store.state.chapterInfo = {
        chapterName: '',
        chapterBg: {},
        chapterBgm: {},
        roleList: []
      }
      if (chapterId === null) {
        this.$router.push({
          path: '/chapterAdd',
          query: {
            bookId: bookId,
            chapterType: '0',
            type: 'add'
          }
        })
      } else {
        this.$router.push({
          path: '/chapterCon',
          query: {
            bookId: bookId,
            chapterId: chapterId,
            type: 'edit'
          }
        })
      }
    },
    // 获取当天时间
    addDate () {
      const nowDate = new Date()
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      }
      const newmonth = date.month > 10 ? date.month : '0' + date.month
      const day = date.date > 10 ? date.date : '0' + date.date
      this.new_date = date.year + '-' + newmonth + '-' + day
    },
    getpagelistdefult () {
      this.axios({
        method: 'get',
        url: '/v1/ugcBookList',
        params: {}
      }).then((res) => {
        // console.log(res)
        if (res.data.head.flag === 0) {
          this.bookList = res.data.content.ugcBookList || []
          if (this.bookList.length === 0) {
            // 加载模板
            this.axios({
              method: 'get',
              url: '/v1/ugcBookDefault',
              params: {}
            }).then((res) => {
              // console.log(res)
              if (res.data.head.flag === 0) {
                this.ugcBookDefault = res.data.content.ugcBookDefault
                // this.backpicPath = res.data.content.ugcBookDefault.ugcBackpic.backpicPath
                // console.log(this.backpicPath)
                // console.log(this.ugcBookDefault.ugcBackpic)
                if (res.data.content.ifInto === 0) {
                  this.ipshow = res.data.content.ifInto
                  setTimeout(this.switch1, 0)
                  setTimeout(this.switch2, 5000)
                  setTimeout(this.switch3, 10000)
                  setTimeout(this.switch4, 15000)
                } else {
                  this.ipshow = null
                }
              }
            })
          }
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    }
  }
}
</script>
