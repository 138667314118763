<style scoped>
  .dialog-con{
    padding: 30px 10px;
    text-align: center;
    color: #202020;
    font-size: 1rem;
    font-weight: 600;
  }
  .btn-sure{
    color: #547CFF;
  }
</style>
<template>
  <div class="dialog-bg">
    <div class="dialog-box">
      <div class="dialog-con">{{dialogContent}}</div>
      <div class="dialog-btn">
        <button class="btn-cancel" v-if="dialogData.btn[0]" @click="dialogCancelFn">{{dialogData.btn[0]}}</button>
        <button class="btn-sure" v-if="dialogData.btn[1]" @click="dialogSureBFn">{{dialogData.btn[1]}}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'dialogContent'
  ],
  data () {
    return {
      dialogData: {
        btn: ['取消', '确定']
      }
    }
  },
  components: {
  },
  mounted () {},
  methods: {
    dialogCancelFn () {
      this.$emit('dialogCancel')
    },
    dialogSureBFn () {
      this.$emit('dialogSure')
    }
  }
}
</script>
